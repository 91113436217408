<template>
  <div class="table-responsive mb-3">
    <div class="row">
    <div class="col-md-2">
      <img style="width: 100%; max-width: 80px;" :alt="responsavel.nome" :src='getImageUrl' onerror="this.src='https://www.royalcargo.com.br/assinatura.royalcargo.com.br/temporario/apresentacao/fun/default.png';">
    </div>
    <div class="col-md-5 vertical-center text-left">
      <div style="word-break: break-word">
      {{ responsavel.nome }} <br/>
        <span class="font-11">
      {{ responsavel.cargo }}
          </span><br/>
      </div>
    </div>
    <div class="col-md-5 vertical-center text-left">
      <div style="word-break: break-word">
        <span v-if="responsavel.email">
          <strong>E-mail:</strong> {{ responsavel.email }} <br/>
        </span>
        <span v-if="responsavel.celular">
          <strong>Celular:</strong> {{ responsavel.celular }} <br/>
        </span>
        <span v-if="responsavel.skype">
          <strong>Skype:</strong> {{ responsavel.skype }} <br/>
        </span>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'ResponsavelBox',
  props: {
    responsavel: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      default: global.instanceApp.$i18n.t('filter.Filter_by')
    },
    noOptions: {
      type: String,
      default: 'Nenhum resultado encontrado'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  data () {
    return {
      searchTerm: null,
      selectedValue: null
    }
  },
  computed: {
    getImageUrl () {
      if (this.responsavel) {
        let now = new Date()
        return 'https://www.royalcargo.com.br/assinatura.royalcargo.com.br/temporario/apresentacao/fun/' + this.responsavel.id_responsavel_externo + '.png?' + now.getTime()
      }
      return ''
    }
  }
}
</script>

<style scoped>
  .vertical-center {
      display: flex;
      align-items: center;
  }
</style>
